.AddProductModal-ingredient-group {
  font-weight: bold;
  background-color: lightgray;
  padding: 5px;
  margin: 2px;
}

.AddProductModal-checkbox {
  margin-left: 4px;
}

.AddProductModal-ingredient-group > span {
  cursor: pointer;
}

.AddProductModal-ingredient-group > span:hover {
  text-decoration: underline;
}

.AddProductModal-margin-top {
  margin-top: 15px;
}
