.ProductTable-pointer {
  cursor: pointer;
}

.ProductTable-link {
  cursor: pointer;
}

.ProductTable-link:hover {
  text-decoration: underline;
}
